<template>
  <div>
    <a
      ref="basket"
      href="#"
      class="flex items-center cursor-pointer h-5 px-3 hover:bg-paper-3 br-1"
      v-on="miniCartEvents">
      <svg-icon
        class="mr-2 relative"
        name="tote-simple"
        width="16px"
        height="16px" />
      <span class="typeset-12 relative top-px">
        {{ numberOfItemsString }}
      </span>
    </a>

    <teleport to="#end-of-body">
      <div
        v-if="showOverlay"
        class="dropdown-overlay bg-paper-3 fixed mr-2 mt-3 md:mt-2 justify-between right-0 p-4 md:mr-0 md:right-auto z-3"
        :style="dropdownStyle">
        <h4 class="mb-4 typeset-3">{{ t("basket-overlay.your-basket") }}</h4>
        <div class="mx-2">
          <div
            class="flex justify-between mb-3 typeset-4 pb-3 border-b border-ink-2">
            <p>{{ t("basket-overlay.items") }}</p>
            <span>{{ numberOfItems }}</span>
          </div>
          <div class="flex mb-4 typeset-4 gap-3 justify-between flex-wrap">
            <p>{{ t("basket-overlay.subtotal") }}</p>
            <span>{{ subtotal.formatted }}</span>
          </div>
          <p class="text-ink-4 typeset-8">
            {{ t("basket-overlay.explainer") }}
          </p>
          <a
            v-if="responsiveMode"
            class="btn btn-large btn-block btn-primary mt-4"
            @click="goToBasketMob">
            {{ t("basket-overlay.cta") }}
          </a>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Cookies from "js-cookie";
import { throttle } from "lodash-es";

import SvgIcon from "../shared/SvgIcon.vue";

export default {
  name: "MiniCartDropdownToggle",
  components: {
    SvgIcon
  },
  emits: ["toggle"],
  data() {
    return {
      fetchedMiniCart: false,
      openBasketAfterMiniCartFetch: false,
      showOverlay: false,
      position: null,
      analyticsEventSent: false
    };
  },
  computed: {
    ...mapState({
      cartPath: state => state.paths.cartPath,
      subtotal: state => state.totals.subtotal,
      responsiveMode: state => state.cart.responsiveMode
    }),
    ...mapGetters({
      numberOfItems: "cart/numberOfItems"
    }),
    isBigCart() {
      return this.numberOfItems > 4;
    },
    isCartEmpty() {
      return this.numberOfItems === 0;
    },
    numberOfItemsString() {
      if (this.numberOfItems === 0) {
        return this.t("cart.mini-cart.title");
      }
      if (this.numberOfItems === 1) {
        return this.t("cart.mini-cart.number-of-items.single", {
          number: this.numberOfItems
        });
      }
      return this.t("cart.mini-cart.number-of-items.multiple", {
        number: this.numberOfItems
      });
    },
    dropdownStyle() {
      if (!this.position) return null;
      if (
        !this.responsiveMode ||
        window.matchMedia("(min-width: 992px)").matches
      ) {
        return {
          top: `${this.position.bottom}px`,
          right: `${window.innerWidth - this.position.right}px`
        };
      }
      return {
        top: `${this.position.bottom - 13}px`,
        zIndex: 101
      };
    },
    miniCartEvents() {
      if (this.isCartEmpty) {
        return {
          click: this.basketClick
        };
      }
      return {
        mouseover: this.openOverlay,
        mouseleave: this.hideOverlay,
        click: this.basketClick
      };
    },
    isHomepage() {
      return ["/", "/us", "/au", "/us/", "/au/"].includes(
        window.location.pathname
      );
    }
  },
  watch: {
    fetchedMiniCart() {
      if (this.openBasketAfterMiniCartFetch) {
        this.$emit("toggle");
      }
    }
  },
  mounted() {
    this.setupComponent();
  },
  methods: {
    async setupComponent() {
      this.setupOverlay();
    },
    setupOverlay() {
      if (this.hasSeenBasketOverlay() || this.isCartEmpty) {
        this.hideOverlay();
      } else if (this.isHomepage) {
        this.setCookie();
        setTimeout(this.openOverlay, 1500);
      }
      this.setOverlayPosition();
      if (!this.isCartEmpty) {
        this.addOverlayListeners();
      }
    },
    scrollListeners() {
      this.setOverlayPosition();
      this.hideOverlay();
    },
    addOverlayListeners() {
      window.addEventListener("scroll", throttle(this.scrollListeners, 100));
      window.addEventListener("click", this.hideOverlay);
      window.addEventListener("resize", this.setOverlayPosition);
    },
    basketClick(e) {
      e.preventDefault();
      if (this.isBigCart) {
        window.location = this.cartPath;
      } else if (!this.fetchedMiniCart) {
        this.openBasketAfterMiniCartFetch = true;
      } else {
        this.$emit("toggle");
      }
    },
    getCookie() {
      return Cookies.get("seenBasketOverlay");
    },
    hasSeenBasketOverlay() {
      if (this.getCookie()) return true;
      return false;
    },
    setCookie() {
      Cookies.set("seenBasketOverlay", true, {
        expires: 7
      });
    },
    setOverlayPosition() {
      const basketLink = window.matchMedia("(min-width: 992px)").matches
        ? this.$refs.basket
        : document.getElementById("mobile-basket");
      if (basketLink) {
        this.position = basketLink.getBoundingClientRect();
      }
    },
    hideOverlay() {
      this.showOverlay = false;
    },
    triggerAnalyticsEvent() {
      if (!this.analyticsEventSent) {
        this.analyticsEventSent = true;
        analytics.track("Basket Overlay Shown");
      }
    },
    async openOverlay() {
      this.triggerAnalyticsEvent();

      if (!this.fetchedMiniCart) {
        await this.fetchMiniCart();
        this.showOverlay = true;
      } else {
        this.showOverlay = true;
      }
    },
    async fetchMiniCart() {
      await this.$store.dispatch("cart/fetchMiniCart");
      this.fetchedMiniCart = true;
    },
    goToBasketMob() {
      window.location = this.cartPath;
      analytics.track("Clicked Go to Basket (Basket Overlay)");
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown-overlay {
  width: 290px;
}
</style>
