<template>
  <div>
    <popover
      origin="top center"
      bordered
      menu-bg-class="bg-white"
      :menu-z-index="53">
      <template #target>
        <a
          href="#"
          class="inline-flex items-center h-5 px-3 hover:bg-paper-4 br-1">
          <div
            class="mr-2 relative"
            style="height: 16px; width: 16px">
            <svg-icon
              name="user"
              data-cy="click-account-from-navigation" />
            <div
              v-if="showCreditNotificationIcon"
              class="br-circle pulse credit-notifier pulse-nav"></div>
          </div>
          <span
            class="typeset-12 mr-2 relative top-px"
            data-cs-mask>
            {{ currentUser.first_name }}
          </span>
          <svg-icon
            name="downarrow-small-thin"
            width="10px"
            height="6px" />
        </a>
      </template>
      <template #body>
        <ul class="list-unstyled m-0">
          <li class="p-3 border-b border-paper-3">
            <p class="typeset-6">
              {{ currentUser.first_name }} {{ currentUser.last_name }}
            </p>
            <p>{{ currentUser.email }}</p>
            <div
              v-if="credit > 0"
              class="mt-3 px-3 py-2 bg-pink typeset-7 flex items-center justify-center">
              {{ creditLabel }}
            </div>
          </li>
          <li
            v-for="(path, index) in filteredPaths"
            :key="index"
            class="relative flex items-center hover:bg-paper">
            <a
              :href="path.url"
              :data-cy="`option-${index}`"
              class="block p-3 w-full"
              @click="trackCreditClick(path.url)">
              {{ path.title }}
            </a>
            <div class="w-5">
              <div
                v-if="isCreditLink(path.url) && showCreditNotificationIcon"
                class="br-circle pulse credit-notifier"></div>
            </div>
          </li>
          <li>
            <a
              :href="logoutPath.url"
              data-cy="logout"
              class="block p-3 text-red hover:bg-paper"
              data-method="delete">
              {{ logoutPath.title }}
            </a>
          </li>
        </ul>
      </template>
    </popover>
  </div>
</template>

<script setup>
import { computed } from "vue";
import Cookies from "js-cookie";
import { storeToRefs } from "pinia";
import useUserNavStore from "../../stores/user-nav-store";
import Popover from "../shared/Popover.vue";
import SvgIcon from "../shared/SvgIcon.vue";

const userNavStore = useUserNavStore();

const { currentUser, credit, creditLabel, filteredPaths, logoutPath } =
  storeToRefs(userNavStore);

const isCreditLink = computed(() => pathUrl => pathUrl === "/account/credit");

const viewedPageCookieExists = computed(
  () => !!Cookies.get("userViewedCreditPage")
);

const showCreditNotificationIcon = computed(
  () => !viewedPageCookieExists.value && credit.value > 0
);

const trackCreditClick = url => {
  if (isCreditLink.value(url)) {
    analytics.track("User Clicked Credit Dropdown", {
      notificationShown: showCreditNotificationIcon.value
    });
  }
};
</script>

<style lang="scss" scoped>
.pulse-nav {
  top: -3px;
  right: 0px;
  position: absolute;
}
</style>
